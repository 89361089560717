@charset "utf-8";
@import url("./fonts.css");

:root {
    --header-height: 64px;
    --sub-header-height: 49px;
    --lnb-width: 240px;
    --menu-active: #333333;
    --menu-off: #ccc;
}

* {
    box-sizing: border-box;
    word-break: break-word;
    -webkit-tap-highlight-color: transparent;
}

*,
html,
body {
    font-family: "Noto Sans KR", sans-serif !important;
    -webkit-text-size-adjust: none;
}
input::-webkit-inner-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

a,
p,
span,
button,
label,
input,
input::placeholder,
textarea {
}

html {
    min-width: 1900px;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f2f1f6;
}
body {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    height: 100%;
    color: #000;
}

html,
body {
    width: 100%;
    height: 100%;
}

main {
    min-height: 100%;
    padding-top: var(--header-height);
    position: relative;
}
.container {
    min-height: calc(100dvh - 60px);
    padding-top: 104px;
}

html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
    margin: 0;
    padding: 0;
    border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-size: 15px;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
#hd ul,
nav ul,
#ft ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
legend {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    text-indent: -9999em;
    overflow: hidden;
}
label,
input,
button,
select,
img {
    vertical-align: middle;
}
input,
button {
    margin: 0;
    padding: 0;
    font-size: 1em;
    outline: none;
}
button {
    cursor: pointer;
    outline: none;
    border: none;
    background: #fff;
}
textarea,
select {
    font-size: 1em;
    outline: none;
}
select {
    margin: 0;
}
p {
    margin: 0;
    padding: 0;
    word-break: keep-all;
}
hr {
    display: none;
}
pre {
    overflow-x: scroll;
    font-size: 1.1em;
}

a:link,
a:visited {
    text-decoration: none;
}
a:hover,
a:focus,
a:active {
    text-decoration: none;
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.pointer {
    cursor: pointer !important;
}
.fontbrr {
    font-family: "br_rregular" !important;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.alignc {
    text-align: center !important;
}
.alignl {
    text-align: left !important;
}
.fontb {
    font-weight: 700;
}
.height100 {
    line-height: 100px;
}
.height200 {
    line-height: 200px;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.color666 {
    color: #666;
}
.color333 {
    color: #333;
}
.fonts18 {
    font-size: 18px;
}

.bgf5f5f5 {
    background: #f5f5f5 !important;
}
.bgfafafa {
    background: #fafafa !important;
}
.bgfff {
    background: #ffffff !important;
}

.cl_blue {
    color: #0656cd !important;
}
.cl_red {
    color: #ff0000 !important;
}

.margint0 {
    margin-top: 0px !important;
}
.margint3 {
    margin-top: 3px;
}
.margint10 {
    margin-top: 10px !important;
}
.margint14 {
    margin-top: 14px !important;
}
.margint20 {
    margin-top: 20px !important;
}
.margint24 {
    margin-top: 24px !important;
}
.margint30 {
    margin-top: 30px !important;
}
.margint36 {
    margin-top: 36px !important;
}
.margint50 {
    margin-top: 50px !important;
}
.margint100 {
    margin-top: 100px !important;
}

.marginb50 {
    margin-bottom: 50px !important;
}
.nomainslider {
    padding-top: 150px !important;
}

.marginl30 {
    margin-left: 30px !important;
}

.padding_tb50 {
    padding: 50px 0px !important;
}
.padding_tb30 {
    padding: 30px 0px !important;
}
.padding_tb20 {
    padding: 20px 0px !important;
}
.padding_tb10 {
    padding: 10px 0px !important;
}
.padding_t10b60 {
    padding: 10px 0px 60px !important;
}

.paddingr10 {
    margin-right: 10px !important;
}
.marginr10 {
    margin-right: 10px !important;
}

.pdl0 {
    padding-left: 0px !important;
}

.wd100 {
    width: 100% !important;
}

.flex-al-center {
    display: flex;
    align-items: center;
}

.vertical_div {
    display: table;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}
.vertical_div > div {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

.borderb2px {
    padding-bottom: 30px;
    border-bottom: 2px solid #ddd;
}

.pagetitle {
    font-size: 20px;
    font-weight: 700;
}
.pagetitle {
    width: 1100px;
    margin: 30px auto 0;
    text-align: left;
}
.pagetitle > span {
    color: #999;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    margin-left: 24px;
}
.pagetitle > span::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    position: absolute;
    left: -12px;
    top: 3px;
    background: rgba(0, 0, 0, 0.12);
}

.pagetitleImg {
    width: 1100px;
    margin: 24px auto 0;
    text-align: left;
}
.pagetitleImg > img {
    width: 100%;
}

.pagesearchtitle {
    font-size: 24px;
    font-weight: 400;
}
.pagesearchtitle {
    width: 1100px;
    margin: 30px auto 0;
    text-align: left;
}
.pagesearchtitle > b {
    color: #0ea55b;
}

.dltitle {
    line-height: 18px;
    top: calc(50% - 16px);
}

.checkbox,
.checkbox2,
.checkbox3,
.checkbox4 {
    display: none;
}
.checkbox + label {
    display: inline-block;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background: url("../images/checkbox_off.svg") no-repeat center center;
    background-size: 24px;
}
.checkbox:checked + label {
    background: url("../images/checkbox_on.svg") no-repeat center center;
    background-size: 24px;
}

.checkbox2 + label {
    display: inline-flex;
    align-items: center;
    padding-left: 28px;
    line-height: 20px;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;

    background: url("../images/checkbox_off.svg") no-repeat center left;
    background-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}
.checkbox2:checked + label {
    background: url("../images/checkbox_on.svg") no-repeat center left;
    background-size: 20px;
}
.checkbox2:nth-last-of-type(1) + label {
    margin-right: 0;
}

.checkbox3 + label {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    padding-left: 50px;
    padding-right: 20px;
    font-size: 14px;
    color: #999;
    height: 44px;
    border-radius: 4px;
    border: solid 1px #f1f1f1;
    background: url("../images/bookmark_off.svg") no-repeat center left 20px;
    background-color: #f1f1f1;
    background-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}
.checkbox3:checked + label {
    color: #5c58ff;
    border: solid 1px #8583ff;
    background: url("../images/bookmark.svg") no-repeat center left 20px;
    background-size: 20px;
    background-color: #fff;
}

.checkbox4 + label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: #999;
    height: 44px;
    border-radius: 100px;
    border: solid 1px #f1f1f1;
    background-color: #f1f1f1;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s;
    width: 100%;
}
.checkbox4 + label::before {
    content: "";
    display: flex;
    width: 20px;
    height: 20px;
    background: url("../images/bookmark_off.svg") no-repeat center center;
    background-size: 20px;
    margin-right: 10px;
    transition: all 0.3s;
}
.checkbox4:checked + label {
    color: #5c58ff;
    border: solid 1px #8583ff;
    background-color: #fff;
}
.checkbox4:checked + label::before {
    content: "";
    display: flex;
    width: 20px;
    height: 20px;
    background: url("../images/bookmark.svg") no-repeat center center;
    background-size: 20px;
}

.radio,
.radio2,
.radio3 {
    display: none;
}
.radio + label {
    display: inline-block;
    line-height: 24px;
    cursor: pointer;
    background: url("../images/radio_off.svg") no-repeat center left;
    background-size: 24px;
    padding-left: 27px;
    font-size: 14px;

    transition: all 0.3s;
}
.radio:checked + label {
    background: url("../images/radio_on.svg") no-repeat center left;
    background-size: 24px;
}

.radio2:nth-last-of-type(1) + label {
    border-right: solid 1px #e4e9f2;
}
.radio2 + label {
    display: inline-block;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
    border: solid 1px #e4e9f2;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    color: #000;
    width: 168px;
    border-right: solid 0px #e4e9f2;
    transition: border 0.3s, border-right 0.3s;
}
.radio2:checked + label {
    border: solid 1px #5c58ff;
    font-weight: bold;
    color: #5c58ff;
}

.radio3 + label {
    display: flex;
    cursor: pointer;
    background: url("../images/radio_off.svg") no-repeat center left 42px;
    background-size: 24px;
    background-color: #fff;
    border-radius: 20px;
    align-items: center;
    width: 800px;
    height: 77px;
    padding-left: 76px;
    font-weight: 700;
    font-size: 20px;
    color: #212121;
    padding-bottom: 2px;
    transition: all 0.3s;
}
.radio3:checked + label {
    background: url("../images/radio_on.svg") no-repeat center left 42px;
    background-size: 24px;
    background-color: #5c58ff;
    color: #fff;
}

.switch {
    display: none;
}
.switch + label {
    display: inline-block;
    height: 20px;
    width: 40px;
    cursor: pointer;
    background-color: #e6e6e6;
    position: relative;
    border-radius: 15px;
    transition: all 0.3s;
}
.switch + label::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #ccc;
    left: 6px;
    top: calc(50% - 4px);
    transition: all 0.3s;
}

.switch:checked + label {
    background-color: rgba(92, 88, 255, 0.2);
}
.switch:checked + label::after {
    left: 26px;
    background-color: #5c58ff;
}

.popup {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 3000000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(7px);
    top: 0;
    left: 0;
    padding-bottom: 50px;
}
.popup.show {
    display: flex;
    animation: popup-show 0.3s;
}
.popup_container {
    width: 320px;
    background-color: #fff;
    padding-bottom: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    animation: popup-container-show 0.3s;
    overflow: hidden;
}
.popup_container_component {
    width: 480px;
    padding-bottom: 40px;
}
.popup_container_component.xl {
    width: 1200px;
    padding-bottom: 0;
}

.react-datepicker-popper {
    animation: popup-container-show 0.3s !important;
    z-index: 100 !important;
    -webkit-animation-fill-mode: forwards !important;
    animation-fill-mode: forwards !important;
}
.react-datepicker__day--selected {
    background-color: #5c58ff !important;
    border-radius: 100% !important;
}
.popup_title_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.popup_title {
    color: var(--Dark, #333);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    background-color: #f1faee;
}
.popup_title_big {
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    color: #212121;
    margin-bottom: 20px;
}
.popup_icon_list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
}
.popup_icon_list > div {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    cursor: pointer;
}
.popup_icon {
    width: 25px;
}
.popup_btn {
    padding: 0 20px;
    margin-top: 30px;
}

.popup_msg {
    color: var(--Dark, #333);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.5;
    max-height: 600px;
    overflow-y: auto;
}
.popup_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.popup_msg_warning {
    color: #ff0000;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.5;
    margin-top: 10px;
    word-break: keep-all;
    padding: 0 10px;
}
.popup_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.popupBtnWrap {
    display: flex;
    justify-content: center;
    margin: 20px;
    gap: 10px;
}

.exit_btn {
    background: url("../images/exit.svg") no-repeat center center;
    background-size: 24px;
    width: 48px;
    height: 48px;
    cursor: pointer;
}
.back_btn {
    background: url("../images/back.svg") no-repeat center center;
    background-size: 24px;
    width: 48px;
    height: 48px;
}
.row_add_btn {
    padding: 0 20px;
    min-width: 100px;
    width: auto !important;
    height: 36px !important;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid var(--Grey_C, #ccc);
}
.cencle_btn {
    color: #718096;
}
.home_btn {
    background: url("../images/home.svg") no-repeat center center;
    background-size: 24px;
    width: 24px;
    height: 24px;
}

.popbtn {
    height: 50px;
    line-height: 50px;
    width: 120px;
    border-radius: 100px;
}

.ok_btn {
    background: var(--Dark, #333);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.cencle_btn {
    background-color: #ccc;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.login_btn {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background: url("../images/login.svg") no-repeat center left/10px;
    background-size: 24px;
    background-color: #5c58ff;
    padding-left: 30px;
}

.btn {
    height: 56px;
    line-height: 56px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
}
.btn > img {
    margin-right: 4px;
}
.btn.disable,
.btn.disable:hover {
    background-color: #e1e1e1;
    color: #fff;
    border: none;
}
.btn.active {
    background-color: var(--Dark, #333);
}
.btn.btn_round {
    border-radius: 100px;
}

.btn1 {
    background: #2a9d8f;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn1_small {
    background: var(--Dark, #51baa4);
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    transition: background-color 0.3s;
    min-width: 120px !important;
    height: 36px !important;
    line-height: 36px !important;
    width: auto !important;
}

.btn2 {
    border-radius: 100px;
    background: var(--Grey_C, #ccc);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
    width: 100%;
    height: 56px;
}

.btn3 {
    border-radius: 100px;
    border: 1px solid var(--Grey_C, #ccc);
    background: var(--White, #fff);
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    height: 56px;
}

.btn4 {
    background: var(--Grey_9, #999);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
    width: 100%;
    height: 56px;
}

.btn5 {
    background-color: #ccc;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    width: auto;
    height: 42px;
    padding: 0 20px;
    transition: all 0.3s;
    min-width: 100px;
}
.btn5:hover {
    background-color: #bbb;
}
.btn6 {
    background-color: #5c58ff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: auto;
    height: 48px;
    padding: 0 20px;
    transition: all 0.3s;
    min-width: 100px;
}
.btn6:hover {
    background-color: #2b27d7;
}
.btn7 {
    background-color: #fff;
    color: #000;
    border: 1px solid #333;
    font-size: 14px;
    font-weight: 500;
    width: auto;
    height: 42px;
    padding: 0 20px;
    transition: all 0.3s;
    min-width: 100px;
}
.btn8 {
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
    font-size: 14px;
    font-weight: 500;
    width: 100px;
    height: 40px;
    padding: 0 20px;
    transition: all 0.3s;
    min-width: 100px;
}

.btn9 {
    background-color: #2a9d8f;
    color: #fff;
    border: 1px solid #2a9d8f;
    font-size: 14px;
    font-weight: 500;
    width: 100px;
    height: 40px;
    padding: 0 20px;
    transition: all 0.3s;
    min-width: 100px;
}
.btn150px {
    width: 150px;
}
.file_label_btn {
    display: flex;
    width: 80px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Dark, #333);
    background: var(--White, #fff);
    color: var(--Dark, #333);
    text-align: center;
    /* Body_b */
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.input_label {
    font-size: 16px;
    color: var(--Dark, #333);
    margin-bottom: 10px;
    display: inline-block;
}
.input_label_help {
    font-size: 12px;
    line-height: 1.5;
    color: #909090;
    margin-top: 2px;
}

.input_box {
    flex: 1;
}
.input_box_full {
    width: 100%;
}

.input_section {
    height: 44px;
    position: relative;
}
.input_section_search {
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
    z-index: 1;
}
.input_section_search input {
    padding-right: 48px !important;
    width: 500px !important;
}
.input_section_search .input_search_button2 {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: url("../images/search.svg") no-repeat center center;
    background-size: 24px;
    width: 48px;
    height: 44px;
    z-index: 2;
}

.input_box_big .input_section {
    height: 64px;
}

.input_box input,
.input_box select {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    border: 1px solid var(--Grey_D, #ddd);
    background: var(--White, #fff);
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    width: 100%;
}
.input_box select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../images/down_black.svg") no-repeat center right 12px;
    background-size: 24px;
    background-color: #fff;
    padding: 0 44px 0 12px;
    position: relative;
}

.input_box textarea {
    display: inline-block;
    height: 100%;
    padding: 0 14px;
    border-radius: 4px;
    border: 1px solid var(--Grey_D, #ddd);
    background: var(--White, #fff);
    font-size: 14px;
    color: #000;
    width: 100%;

    padding: 12px;
    resize: none;
    height: 200px;
}

.input_select_box {
    background-color: #fff;
    border-radius: 20px;
    height: 56px;
    display: flex;
    align-items: center;
    width: 400px;
}
.input_select_box > img {
    margin-right: 8px;
}
.input_select_box select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: url("../images/down_black.svg") no-repeat center right 24px;
    background-size: 36px;
    padding: 0 65px 0 56px;
    font-size: 20px;
    line-height: 29px;
    height: 100%;
    color: #333333;
    flex: 1;
}

.input_select_box::after {
    content: "";
    width: 24px;
    height: 24px;
    background: url("../images/date.svg") no-repeat center left;
    background-size: 100%;
    position: absolute;
    left: 24px;
}

.input_select_box_multi.label_left {
    display: flex;
    align-items: flex-start;
}

.input_select_box_multi > .input_label {
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}

.input_select_box_multi.label_left > .input_label {
    margin-bottom: 0;
    line-height: 44px;
    width: 60px;
}

.input_select_box_multi > .input_select_box_multi_form {
    border-radius: 4px;
    border: 1px solid var(--Grey_D, #ddd);
    background: var(--White, #fff);
    flex: 1;
}
.input_select_box_multi > .input_select_box_multi_form > .title {
    color: var(--Grey_3, #333);
    /* Body */
    font-size: 14px;
    font-weight: 400;
    line-height: 44px;
    width: 100%;
    background: url("../images/down_black.svg") no-repeat center right 12px;
    background-size: 24px;
    padding: 0 12px;
    cursor: pointer;
}
.input_select_box_multi.active > .input_select_box_multi_form > .title {
    background: url("../images/up_arrow.svg") no-repeat center right 12px;
    background-size: 24px;
}

.input_select_box_multi .input_select_box_multi_options {
    border-top: 1px solid var(--Grey_D, #ddd);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 172px;
    overflow: auto;
}

.input_range_box {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
}
.input_range_box > p {
    color: var(--Dark, #333);
    /* Body */
    font-size: 14px;
    font-weight: 400;
}
.input_range_box input {
    height: 100%;
    padding: 0 12px;
    border: 1px solid var(--Grey_D, #ddd);
    background: var(--White, #fff);
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    width: 100%;
}
.input_label_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.input_label_box > .input_label {
    margin-bottom: 0;
}
.input_label_box > .input_ex {
    color: var(--Grey_9, #999);
    font-size: 12px;
    font-weight: 400;
}
.input_box_big input {
    padding: 0 20px;
    border-radius: 8px;
    font-size: 20px;
}
.input_box input.disable,
.input_box textarea.disable {
    background-color: #f5f5f5;
}

.input_box input:not(.disable),
.input_box select:not(.disable),
.input_box textarea,
.input_range_box input {
    transition: border 0.3s;
}

.input_box input::placeholder,
.input_box textarea::placeholder,
.input_range_box input::placeholder {
    font-size: 14px;
    color: #999;
}

.input_box_big input::placeholder {
    font-size: 20px;
}

.input_box input:focus:not(.disable),
.input_box select:focus:not(.disable),
.input_box textarea:focus:not(.disable),
.input_range_box input:focus:not(.disable) {
    border: solid 1px #333;
    outline: none;
}
.input_box select:focus:not(.disable) {
}

.input_box select.disable {
    background-color: #fafafa;
    color: #909090;
}
.input_box input.disable:focus {
}
.input_box input.disable.withButton:focus {
    border-right: none !important;
}
.input_count {
    font-size: 12px;
    line-height: 1.5;
    color: #8f9bb3;
    text-align: right;
}
.input_count > span {
    color: #718096;
    font-weight: bold;
}
.input_error {
    font-size: 12px;
    line-height: 17px;
    color: #ff0000;
    margin-top: 10px;
}
.input_error_big {
    font-size: 14px;
    line-height: 20px;
    color: #ff0000;
    margin-top: 10px;
    text-align: center;
}
.input_success {
    font-size: 12px;
    line-height: 1.5;
    color: #48bb78;
    background: url("../images/checkmark.svg") no-repeat center left;
    background-size: 12px;
    padding-left: 15px;
    margin-top: 2px;
}
.input_help {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #666666;
    margin-top: 4px;
}
.input_help > span {
    font-weight: 700;
    color: #212121;
}

.input_box .withButton,
.input_box .withButton:focus {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: calc(100% - 88px);
}

.input_with_button {
    border: 1px solid #e4e9f2;
    background-color: #fff;
    height: 100%;
    width: 88px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 16px;
    color: #718096;
}
.input_search_button {
    background: url("../images/search.svg") no-repeat center center;
    background-size: 24px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.input_reset_button {
    width: 24px;
    height: 24px;
    background: url("../images/x-circle.svg") no-repeat center center;
    background-size: 100%;
    margin-right: 10px;
}
.withSearch:focus {
    /* border: 1px solid #F5F4F9 !important; */
}

.input_box .withButton:not(.disable) + .input_with_button {
    transition: border 0.3s;
}
.input_box .withButton:focus:not(.disable) + .input_with_button {
    border: solid 1px #333;
    border-left: 1px solid #e4e9f2;
}
.input_box .withText {
    padding: 0 34px 0 14px;
    text-align: right;
}
.input_with_text {
    display: flex;
    font-size: 16px;
    position: absolute;
    right: 14px;
    top: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.input_box input[type="file"] {
    display: none;
}
.input_box_no_style {
    margin-bottom: 10px;
}

.input_box_no_style .input_section {
    height: auto;
}
.input_box_no_style input {
    background-color: transparent;
    border: none;
    padding: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #212121;
}
.input_box_no_style textarea {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 16px;
    line-height: 23px;
    color: #666666;
    height: auto;
}
.input_box_no_style .input_count {
    display: none;
}

.input_box_no_style input:focus,
.input_box_no_style textarea:focus {
    background-color: transparent !important;
    border: none !important;
}

.input_box_no_style input::placeholder {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: #999;
}

.input_file_info {
    display: flex;
    height: 100%;
    background-color: #fff;
    font-size: 14px;
    color: #212121;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-width: 335px;
}
.input_date_label {
    display: flex;
    height: 100%;
    background-color: #fff;
    font-size: 14px;
    color: #212121;
    align-items: center;
    cursor: pointer;
    max-width: 335px;
    border: solid 1px #ddd;
    border-radius: 4px;
    transition: border 0.3s;
    background: url("../images/calendar.svg") no-repeat center right 12px;
    background-size: 24px;
    padding: 0 14px;
}

.input_file_info {
    padding-right: 0;
    cursor: default;
}

.input_file_label.disable,
.input_file_info.disable,
.input_date_label.disable {
    background-color: #fafafa;
    color: #909090;
}

.input_date_label {
}

.input_date_label.focus {
}

.input_file_info_name {
    font-size: 16px;
    color: #000;
    width: calc(100% - 90px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.input_file_info.disable > .input_file_info_name {
    color: #909090;
}
.input_file_info_name.with_button {
    width: calc(100% - 134px);
}
.input_file_info_img {
    height: 100%;
}
.input_file_info_img > img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 8px;
}

.trash_btn {
    width: 46px;
    height: 100%;
    background: url("../images/trash.svg") no-repeat center center;
    background-size: 24px;
}
.trash_btn_text {
    height: 100%;
    background: url("../images/trash.svg") no-repeat center left;
    background-size: 24px;
    padding-left: 28px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #666666;
    cursor: pointer;
}
.trash_gray_btn {
    width: 20px;
    height: 20px;
    background: url("../images/trash_gray.svg") no-repeat center center;
    background-size: cover;
    cursor: pointer;
}

.regist_btn_text {
    height: 100%;
    background: url("../images/regist_blue.svg") no-repeat center left;
    background-size: 24px;
    padding-left: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #5c58ff;
    cursor: pointer;
    font-weight: 500;
}
.delete_btn {
    width: 24px;
    height: 24px;
    background: url("../images/x-circle.svg") no-repeat center center;
    background-size: 100%;
}
.delete_btn_big {
    width: 40px;
    height: 40px;
    background: url("../images/x-circle.svg") no-repeat center center;
    background-size: 24px;
}
.delete_btn_big_white {
    width: 40px;
    height: 40px;
    background: url("../images/x-circle-white.svg") no-repeat center center;
    background-size: 24px;
}
.down_btn {
    width: 24px;
    height: 24px;
    background: url("../images/dowload.svg") no-repeat center center;
    background-size: 100%;
}
.edit_btn {
    width: 48px;
    height: 48px;
    background: url("../images/edit.svg") no-repeat center center;
    background-size: 100%;
}
.close_btn {
    width: 24px;
    height: 24px;
    background: url("../images/close.svg") no-repeat center center;
    background-size: 100%;
}
.top_btn {
    width: 24px;
    height: 24px;
    background: url("../images/top.svg") no-repeat center center;
    background-size: 100%;
    opacity: 0.4;
    cursor: default;
    transition: all 0.3s;
}
.bell_btn {
    width: 36px;
    height: 36px;
    background: url("../images/bell.svg") no-repeat center center;
    background-size: 24px;
    position: relative;
}
.bell_btn > span {
    border-radius: 100px;
    background: var(--Primary, #0656cd);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--White, #fff);
    text-align: center;
    font-size: 10px;
    font-weight: 800;
    padding: 4px;
    position: absolute;
    top: -3px;
    right: 0;
    min-width: 21px;
}
.top_btn.active {
    opacity: 1;
    cursor: pointer;
}
.bottom_btn {
    width: 24px;
    height: 24px;
    background: url("../images/bottom.svg") no-repeat center center;
    background-size: 100%;
    opacity: 0.4;
    cursor: default;
    transition: all 0.3s;
}
.bottom_btn.active {
    opacity: 1;
    cursor: pointer;
}
.add_btn {
    width: 36px;
    height: 36px;
    background: url("../images/add.svg") no-repeat center center;
    background-size: 100%;
}
.add_btn_text {
    background: url("../images/add.svg") no-repeat center left;
    background-size: 32px;
    padding-left: 40px;
    font-weight: 700;
    font-size: 20px;
    color: #5c58ff;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.add_btn2 {
    width: 36px;
    height: 36px;
    background: url("../images/add.svg") no-repeat center center;
    background-size: 100%;
    cursor: pointer;
}
.issue_up_btn {
    width: 200px;
    height: 36px;
    background: url("../images/up.svg") no-repeat center center;
    background-size: 40px;
    position: absolute;
    left: calc(50% - 100px);
    top: 0;
    transition: all 0.3s;
}
.issue_up_btn.active {
    transform: rotate(-180deg);
}

.setting_btn {
    width: 32px;
    height: 32px;
    background: url("../images/setting.svg") no-repeat center center;
    background-size: 32px;
}
.link_btn {
    width: 20px;
    height: 20px;
    background: url("../images/link.svg") no-repeat center center;
    background-size: 20px;
}
.view_btn {
    width: 100%;
    height: 100%;
    background: url("../images/search_small.svg") no-repeat center center;
    background-size: 20px;
}
.input_file_with_button {
    color: #718096;
    font-size: 16px;
    height: 100%;
    border-left: 1px solid #e4e9f2;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    cursor: pointer;
}
.input_file_label {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: solid 1px #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: url("../images/camera.svg") no-repeat center center;
    background-size: 30px;
    background-color: #f5f5f5;
    overflow: hidden;
}
.input_file_label_multi {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    border: solid 1px #e1e1e1;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: url("../images/camera.svg") no-repeat center center;
    background-size: 30px;
    background-color: #f5f5f5;
}

.input_section_multi {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.input_multi_preview {
    width: 100px;
    height: 100px;
    display: inline-block;
    position: relative;
}
.input_multi_preview > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.img_delete_btn {
    width: 24px;
    height: 24px;
    background: url("../images/exit_white.svg") no-repeat center center;
    background-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
}

.grid_pin_btn {
    background: url("../images/grid_pin_off.svg") no-repeat center center;
    background-size: cover;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.grid_pin_btn.on {
    background: url("../images/grid_pin_on.svg") no-repeat center center;
    background-size: cover;
}
.trash_btn_red {
    width: 64px;
    height: 64px;
    background: url("../images/trash_red.svg") no-repeat center center;
    background-size: 24px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    height: 100%;
    width: 100%;
}

.input_date_section_multi {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input_date_section_multi > span {
    font-size: 14px;
    color: #8f9bb3;
    margin: 0 9px 0 10px;
}

.preview {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.input_file_label {
    position: relative;
    display: inline-flex;
    cursor: pointer;
}
.input_file_label_doc {
    display: flex;
    align-items: center;
    gap: 8px;
}
.input_file_label_doc > label {
}
.input_file_label_doc_names {
    display: flex;
    align-items: center;
    flex: 1;
}
.input_file_label_doc_names > p {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 100%;
    line-height: 40px;

    cursor: pointer;
}
.input_file_label_doc_names > p:hover {
    text-decoration: underline;
}
.input_file_label_doc > .link {
    text-decoration: underline;
    cursor: pointer;
}
.input_preview_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #fff;
}
.input_preview_button {
    background-color: #5c58ff;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: -15px;
}

.input_preview_button > img {
    width: 20px;
}

.loading {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
}
.loading.show {
    display: flex;
}

.loading_spin {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}
.pop_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

@property --p {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

.praph_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pie {
    --p: 20;
    --b: 8px;
    --c: darkred;
    --w: 106px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    place-content: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
}
.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}
.pie:before {
    inset: 0;
    background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
            var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
    -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - var(--b)),
        #000 calc(100% - var(--b))
    );
    mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - var(--b)),
        #000 calc(100% - var(--b))
    );
}
.pie:after {
    inset: calc(50% - var(--b) / 2);
    background: var(--c);
    transform: rotate(calc(var(--p) * 3.6deg))
        translateY(calc(50% - var(--w) / 2));
}
.animate {
    animation: p 1s 0.5s both;
}
.no-round:before {
    background-size: 0 0, auto;
}
.no-round:after {
    content: none;
}
.pie_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100%;
    border: 8px solid #eee;
}
.graph_label_box {
    margin-left: 24px;
}
.graph_label_box > p {
    font-size: 12px;
    color: #212121;
    padding-left: 16px;
    position: relative;
    --c: #eee;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.graph_label_box > p:nth-last-of-type(1) {
    margin-bottom: 0;
}
.graph_label_box > p:after {
    content: "";
    background: var(--c);
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
}

.chat_icon {
    background: url("../images/chat.svg") no-repeat center left;
    background-size: 24px;
    padding-left: 28px;
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;
}

.null_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.null_box > img {
    width: 20px;
}
.null_box > p {
    color: #999;
    font-size: 14px;
    line-height: 200px;
}
.null_li {
    flex: 1;
    gap: 6px;
}
.null_full {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    line-height: 200px;
    color: #999;
}
.ing_btn {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 64px;
    background-color: #212121;
    border-radius: 100px;
    padding-bottom: 1px;
}
.before_btn {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 64px;
    background-color: #999;
    border-radius: 100px;
    padding-bottom: 1px;
}

.ing_btn_big {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 40px;
    background-color: #212121;
    border-radius: 100px;
    padding: 0 24.5px 2px 24.5px;
}

.ing_btn_big2 {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 44px;
    background-color: #212121;
    border-radius: 100px;
    text-align: center;
}

.before_btn_big {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 40px;
    background-color: #999;
    border-radius: 100px;
    padding: 0 24.5px 2px 24.5px;
}
.before_btn_big2 {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 40px;
    background-color: #999;
    border-radius: 100px;
    text-align: center;
}
.done_btn_big {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 40px;
    border-radius: 100px;
    padding: 0 24.5px 2px 48px;

    background: url("../images/check_white.svg") no-repeat center left 26px;
    background-size: 12px;
    background-color: #8583ff;
}
.done_btn_big2 {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 40px;
    border-radius: 100px;
    background-color: #8583ff;
}

.done_btn_big2::before {
    content: "";
    display: flex;
    width: 12px;
    height: 12px;
    background: url("../images/check_white.svg") no-repeat center center;
    background-size: 12px;
    margin-right: 10px;
}

.Toastify__toast-body > div:last-child {
    font-size: 16px !important;
}

.Toastify__toast-container {
    z-index: 20000001 !important;
}

.tableStyle1 {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
}

.tableStyle1 th {
    color: var(--Grey_6, #666);
    text-align: center;
    font-size: 14px;
    background: var(--Tab, #ededed);
    height: 44px;
    width: 150px !important;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    border-top: 1px solid var(--Dark, #333);
    padding: 4px;
    word-break: keep-all;
}
.tableStyle1 td {
    border-bottom: 1px solid var(--Grey_D, #ddd);
    padding: 12px 8px;
    text-align: center;
    background-color: #fff;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
}

.tableStyle1 .wd500 {
    width: 500px;
}
.tableStyle1 .wd400 {
    width: 400px;
}
.tableStyle1 .wd300 {
    width: 300px;
}
.tableStyle1 .wd220 {
    width: 220px;
}
.tableStyle1 .wd150 {
    width: 150px;
}
.tableStyle1 .wd130 {
    width: 130px;
}
.tableStyle1 .wd110 {
    width: 110px;
}
.tableStyle1 .wd90 {
    width: 90px;
}
.tableStyle1 .wd80 {
    width: 80px;
}
.tableStyle1 .wd50 {
    width: 50px;
}
.tableStyle1 .wdFull {
    width: 100%;
}
.tableStyle1 .align_center {
    text-align: center;
}
.tableStyle1 .link {
    text-decoration: underline;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    word-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.tableStyle1 .td_etc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    word-wrap: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.null_td {
    text-align: center !important;
    color: #909090 !important;
    font-size: 14px !important;
    line-height: 3 !important;
}
.null_p {
    text-align: center !important;
    color: #909090 !important;
    font-size: 14px !important;
}

.pdf_viewbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: auto;
}
.pdf_viewbox > div {
    width: 1080px;
    margin: 0 auto;
}
.react-datepicker__navigation {
    top: 8px !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    top: 3px !important;
}
@keyframes p {
    from {
        --p: 0;
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes popup-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes popup-container-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.pagination ul {
    list-style: none;
    padding: 0;
}
ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}
ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
}
ul.pagination li a {
    text-decoration: none;
    color: #666;
    font-size: 1rem;
}
ul.pagination li.active a {
    color: #000;
    font-weight: 700;
}
ul.pagination li.active {
}
ul.pagination li a:hover,
ul.pagination li a.active {
    color: #000;
    font-weight: 700;
}
ul.pagination li [aria-label="Go to first page"] {
    content: url("../images/pagination_first.svg");
}

ul.pagination li [aria-label="Go to previous page"] {
    content: url("../images/pagination_left.svg");
}

ul.pagination li [aria-label="Go to next page"] {
    content: url("../images/pagination_right.svg");
}
ul.pagination li [aria-label="Go to last page"] {
    content: url("../images/pagination_last.svg");
}

.input_date_label {
    background: url("../images/calendar.svg") no-repeat center right 12px;
}
.SpaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.MarginTop20 {
    margin-top: 20px;
}
.BoxInfo .sub .react-datepicker-wrapper {
    width: auto;
}

.tableInputWrap {
    height: 44px;
}
.tableInputWrap input {
    display: inline-block;
    height: 100%;
    padding: 0 12px;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    width: 100%;
}
.contentPic {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}
.contentPic .imgWrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.contentPic .imgWrap .uploadImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.contentPic .imgWrap .del {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.DeleteBtnWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}
.DeleteBtn {
    margin: 10px 0;
    color: #ff0000;
    cursor: pointer;
    padding: 10px;
    width: 150px;
    font-size: 20px;
    border: 1px solid #ddd;
    background-color: white;
}
.ReviveBtn {
    margin: 10px 0;
    cursor: pointer;
    padding: 10px;
    width: 150px;
    font-size: 20px;
    border: 1px solid #2a9d8f;
    color: #2a9d8f;
    background-color: #ebffef;
}
