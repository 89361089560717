.postCode {
    z-index: 5;
    position: absolute;

    width: 350px;
}
input[readonly] {
    background-color: #ddd;
}
.header {
    background-color: #004889;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    border-bottom: 1px solid var(--Grey_D, #ddd);
}
.header_search {
    display: flex;
    align-items: center;
}
.header_search p {
    font-size: 16px;
    font-weight: 700;
}
.header_search input {
    height: 44px;
    display: inline-block;
    height: 100%;
    padding: 12px;
    border: 1px solid var(--Grey_D, #ddd);
    background: var(--White, #fff);
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    width: 330px;
}
.header_logo {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 100%;
}
.header_logo > img {
    width: calc(var(--lnb-width) - 40px);
    height: 100%;
    object-fit: contain;
}
.header_logo > p {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
}
.header_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - var(--lnb-width));
    height: 100%;
    padding-right: 20px;
    gap: 10px;
}
.header_menu {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #51baa4;
}
.header_menu_one {
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 4px;

    background-color: #51baa4;
    color: white;
    /* Title */
    font-size: 16px;
    font-weight: 400;
}
.header_menu_one > img {
    fill: var(--menu-off);
}
.header_menu_one.active {
    color: #fff;
    font-weight: 700;
    background-color: #1b8d75 !important;
}

.header_menu_one.disabled {
    color: #fff;
    font-weight: 700;
    background-color: #aaa !important;
}

.header_menu_one:nth-last-of-type(1) {
    margin-right: 0;
}
/* .header_menu_one::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #004889;
    bottom: 0;
}
.header_menu_one.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #fff;
    bottom: 0;
} */
.header_search {
    display: flex;
    align-items: center;
    gap: 20px;
}
.header_buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}
.header_buttons > .profile_name {
    color: #fff;
    font-weight: bold;
}

.header_profile {
    margin-right: 20px;
}
.header_profile > p {
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    padding-bottom: 1px;
}

.header_profile > img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 8px;
    border-radius: 100%;
}
.header.dark {
    background-color: #51baa4;
}
.dark .header_menu_one {
    background-color: #51baa4;
}
.dark .header_menu_one::after {
    background-color: #333;
}
.dark .header_menu_one.active::after {
    background-color: #1b8d75;
}

.logout_btn {
    background: url("../images/logout.svg") no-repeat center center;
    background-size: 24px;
    width: 24px;
    height: 24px;
}

.footer {
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer > p {
    font-size: 12px;
    color: #666666;
}

.login_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    /* background: url('../images/login_bg.png') noß-repeat center center; */
    background-size: cover;
}

.login_box {
    width: 560px;
    background: #ffffff;
    border-radius: 20px;
    padding: 80px;
    border: 1px solid var(--Grey_C, #ccc);
}
.login_box > img {
    margin: 0 auto;
    display: block;
}
.login_input {
    margin-top: 60px;
}
.login_input > .sub_title {
    color: var(--Dark, #333);
    text-align: center;
    /* H3 */
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}
.login_input > .btn {
    margin-top: 12px;
}

.left_menu {
    width: var(--lnb-width);
    max-height: calc(100dvh - var(--header-height));
    height: 100%;
    overflow: auto;
    position: fixed;
    top: var(--header-height);
    left: 0;
    z-index: 1;
    padding-bottom: 56px;
    border-right: 1px solid #666666;
    background: var(--Background, #f2f3f4);
}

.left_menu_one {
}
.left_menu_one > .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 20px;
    height: 48px;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 700;
    background-color: #fff;
}
.left_menu_one > .title::after {
    content: "";
    background: url("../images/add.svg") no-repeat center center;
    background-size: 20px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
}
.left_menu_one.active > .title::after {
    background: url("../images/minus.svg") no-repeat center center;
    background-size: 20px;
}
.left_menu_one.dot > .title::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    right: 48px;
    background-color: #f00;
    border-radius: 100%;
}

.left_menu_one > .left_menu_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    cursor: pointer;
}
.left_menu_one > .left_menu_title > div {
    display: flex;
    align-items: center;
}
.left_menu_one > .left_menu_title .title {
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
}
.left_menu_one > .left_menu_title .icon {
    margin-right: 8px;
}

.left_menu_one > .left_menu_title > img {
    transition: all 0.3s;
}

.left_menu_one .left_menu_body {
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid var(--Grey_D, #ddd);
}

.left_menu_one.active > .left_menu_title > img {
    transform: rotate(-180deg);
}

.left_menu .btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: var(--lnb-width);
}
.left_menu_dim {
    position: fixed;
    left: 0;
    bottom: 0;
    width: var(--lnb-width);
    height: calc(100dvh - var(--header-height));
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.left_nav {
    width: var(--lnb-width);
    max-height: calc(100dvh - var(--header-height));
    height: 100%;
    overflow: auto;
    position: fixed;
    top: var(--header-height);
    left: 0;
    padding-bottom: 56px;
    border-right: 1px solid #666666;
    z-index: 1;
    background: var(--Background, #fff);
}
.left_nav_one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    color: #ccc;
}
.left_nav_one.active {
    background-color: #cbf3f0;
    color: #2a9d8f;
}
.left_nav_count {
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    background-color: #004889;
    border-radius: 12px;
    padding: 0 12px;
}

.summary_box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.summary_box > .title {
    color: var(--Dark, #333);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.table_box_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.table_box_list.row {
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
}
.table_box_list > div {
    flex: 1;
}
.table_box {
    border-radius: 12px;
    border: 1px solid var(--Grey_E, #eee);
    background: var(--White, #fff);
    padding: 40px;
}
.table_box > .title {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}
.table_box > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 700;
}
.table_box > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.table_box > .title_box > .btn_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.table_box > .title_box > .btn_box > button {
    font-size: 13px;
    width: auto;
    height: 36px;
    padding: 0 25px;
    min-width: 120px;
}

.table_box > .table1 {
    width: 100%;
}
.table1 {
    border-spacing: 0;
    border-bottom: 1px solid var(--Grey_D, #ddd);
}
.table1 th,
.table1 td {
    border-top: 1px solid var(--Grey_D, #ddd);
}
.table1 th {
    color: var(--Grey_6, #666);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding: 8px 0;
    background: var(--Tab, #ededed);
}
.table1 td {
    color: var(--Grey_6, #333);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding: 8px;
}
.table1 .bd > td {
    font-weight: 700;
    border-top: 1px solid var(--Dark, #333);
}

.content_center {
    padding: 40px 40px 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.content_center > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}
.content {
    padding: 40px 40px 20px 40px;
    width: 1440px;
    margin-left: var(--lnb-width);
    position: relative;
}
.content:focus {
    outline: #2a9d8f !important;
}
@media screen and (min-width: 1920px) {
    .content {
        width: calc(100% - var(--lnb-width));
    }
}
.content > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
}
.content > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
}
.content > .title_box > .btn_box {
    display: flex;
    align-items: center;
    gap: 10px;
}
.content > .title_box > .btn_box > button {
    min-width: 120px;
    height: 40px;
    font-size: 14px;
}
.content_nolnb {
    padding: 40px;
    width: calc(100% - 360px);
    margin-top: var(--sub-header-height);
}
.content_nolnb > .title {
    color: var(--Dark, #333);
    font-size: 32px;
    font-weight: 400;
}
.content_nolnb > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content_nolnb > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
}
.content_nolnb > .title_box > .btn_box {
    display: flex;
    align-items: center;
    gap: 10px;
}
.content_nolnb > .title_box > .btn_box > button {
    min-width: 120px;
    height: 40px;
    font-size: 14px;
}
.content_full {
    padding: 40px;
    width: 100%;
}
.content_full > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
}
.content_full > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content_full > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
}
.content_full > .title_box > .btn_box {
    display: flex;
    align-items: center;
    gap: 10px;
}
.content_full > .title_box > .btn_box > button {
    min-width: 120px;
    height: 40px;
    font-size: 14px;
}

.content_main {
    width: calc(100% - var(--lnb-width));
    margin-left: var(--lnb-width);
    height: calc(100dvh - var(--header-height));
    position: relative;
}
.content_form {
    padding: 40px;
    width: calc(100% - var(--lnb-width));
    height: calc(100% - var(--header-height));
    position: fixed;
    left: var(--lnb-width);
    top: var(--header-height);
    background-color: #f2f1f6;
    z-index: 100;
    overflow: auto;
}
.content_form > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}
.content_form > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
}

#map {
    width: 100%;
    height: 100%;
}

.grid_container {
    margin-top: 20px;
    position: sticky;
    top: 77px;
}
.grid_container > .top_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
}
.grid_container > .top_box > .count {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 700;
}
.grid_container > .top_box > .count > span {
    font-weight: 700;
}
.grid_container > .top_box > div {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
}
.grid_container > .top_box > div .input_section {
    height: 38px;
    width: 250px;
}
.grid_container > .top_box > .btn_box {
}
.grid_container > .top_box > .btn_box > button {
    height: 40px;
    font-size: 14px;
    min-width: 80px;
}
.grid_container > .list_top_box {
    height: 40px;
    margin-bottom: 8px;
}
.grid_container > .top_box > .with_btn {
    display: flex;
    align-items: center;
    white-space: break-spaces;
}
.grid_container > .top_box > .with_btn > button {
    width: 80px;
    min-width: 80px;
    height: 36px;
    line-height: 36px;
    padding: 0;
    margin-left: 10px;
    font-size: 12px;
}

.reset_black_btn {
    background: url("../images/reset_black.svg") no-repeat center left;
    background-size: 18px;
    padding-left: 24px;
    color: var(--Dark, #333);
    font-size: 12px;
    font-weight: 400;
    height: 100%;
}
.filter_btn {
    background: url("../images/setting.svg") no-repeat center left;
    background-size: 20px;
    padding-left: 24px;
    color: var(--Dark, #333);
    font-size: 12px;
    font-weight: 400;
    height: 100%;
}

.table_scroll > div {
    width: 100% !important;
    height: calc(100dvh - var(--header-height) - 360px);
}
.table_scroll table {
    width: 100%;
    table-layout: "fixed";
    border-collapse: separate;
    border-spacing: 0;
}
.table_scroll thead {
    top: var(--header-height) !important;
}
.table_scroll.thead_0 thead {
    top: 0 !important;
}
.table_scroll.empty > div {
    overflow: hidden !important;
}
.table_scroll tr {
}
.table_scroll th {
    color: var(--Grey_6, #666);
    text-align: center;
    font-size: 14px;
    background: var(--Tab, #f1faee);
    height: 44px;
    width: 150px !important;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    border-top: 1px solid var(--Dark, #ddd);
    border-left: 1px solid var(--Dark, #ddd);
    padding: 4px;
    word-break: keep-all;
}
.table_scroll th:first-child {
    border-left: 0;
}
.table_scroll td {
    border-bottom: 1px solid var(--Grey_D, #ddd);
    padding: 12px 8px;
    text-align: center;
    background-color: #fff;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
}
.table_scroll th.xs {
    width: 70px !important;
}
.table_scroll th.s {
    width: 90px !important;
}
.table_scroll th.m {
    width: 150px !important;
}
.table_scroll th.l {
    width: 300px !important;
}
.table_scroll th.xl {
    width: 500px !important;
}
.table_scroll th.od {
    cursor: pointer;
    position: relative;
}
.table_scroll > .btn_box {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
}
.table_scroll > .btn_box > button {
    height: 36px !important;
    line-height: 36px !important;
    min-width: 100px;
    margin: 0;
    font-size: 14px;
}
.table_scroll td.link {
    cursor: pointer;
    text-decoration: underline;
}
.table_scroll td.ellipsis {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.table_scroll td.pre {
    white-space: pre-line !important;
}
.table_scroll_detail thead {
    top: 0 !important;
}
.table_scroll_detail.empty > div {
    height: 44px !important;
}
.table_scroll_detail.empty tbody {
    display: none;
}
.table_scroll .calendar tr td {
    vertical-align: top;
}
.table_scroll .calendar tr td .calendarDayWrap {
    height: 150px;
    width: 100%;
    border: 1px solid #ddd;
    background-color: #f1faee;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px;
}
.table_scroll .calendar tr td .calendarDayWrap .line {
    height: 0;
    width: 100%;
    margin: 5px 0;
}
.table_scroll .calendar tr td .calendarDayWrap .work {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    color: white;
}
.table_scroll .calendar tr td .calendarDayWrap .info {
    text-align: left;
    margin-top: 5px;
    font-size: 16px;
}
.table_scroll .visitCalendar tr td {
    min-height: 200px;
    vertical-align: top;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap {
    height: 100%;
    border: 1px solid #ddd;
    background-color: #f1faee;
    border-radius: 5px;
    padding: 10px 0px;
    margin-bottom: 10px;
    position: relative;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap .NoClkWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 5px;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap .time {
    font-size: 18px;

    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap .job {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap .job div {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
    /* min-width: 0px; */
}
.table_scroll .visitCalendar tr td .visitCalendarWrap .number {
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap .location {
    padding-bottom: 10px;
    font-size: 18px;
}
.table_scroll .visitCalendar tr td .visitCalendarWrap button {
    width: 90%;
    padding: 5px;
    background-color: #ddd;
}
.td_badge {
    text-decoration: none !important;
    background-color: #0656cd;
    color: #fff;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: 700;
    margin-right: 10px;
}

.table_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: auto !important;
}
.table_loading.init {
    height: 300px !important;
}
.table_scroll.empty > .table_loading {
    display: none;
}
.td_map {
    background: url("../images/location.svg") no-repeat center center;
    background-size: 20px;
    cursor: pointer;
}
.td_detail {
    background: url("../images/search_small.svg") no-repeat center center;
    background-size: 20px;
    cursor: pointer;
}
.td_btn_box > .btn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.td_btn_box > .btn_box > button {
    width: 80px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
}

.no_scollbar::-webkit-scrollbar {
    display: none;
}

.table_scroll *::-webkit-scrollbar {
    width: 14px !important;
    height: 14px !important;
}
.table_scroll *::-webkit-scrollbar-corner {
    display: none !important;
}
.filter_box {
    position: absolute;
    top: calc(var(--header-height) + 80px);
    right: 20px;
    background-color: #fff;
    width: 240px;
    height: 70dvh;
    z-index: 3;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
.filter_box > div {
    height: calc(100% - 36px - 48px);
}
.filter_box_content {
    height: 100%;
}
.filter_box_content > .title {
    color: var(--Dark, #333);
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin: 0 20px;
}
.filter_box_content_list {
    overflow: auto;
    height: calc(100% - 63px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.filter_box_content_list > div {
    display: flex;
    align-items: center;
}
.filter_box_content_list > div > label {
    flex: 1;
}
.od.desc::before,
.od.asc::before {
    /* content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #F00;
    border-radius: 100%; */
}
.od::after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("../images/od.svg") no-repeat center center;
    background-size: 18px;
    cursor: pointer;
    vertical-align: inherit;
    margin-left: 4px;
}
.od.desc::after {
    background: url("../images/table_down.svg") no-repeat center center;
    background-size: 22px;
}
.od.asc::after {
    background: url("../images/table_down.svg") no-repeat center center;
    background-size: 22px;
    transform: rotate(180deg);
}
.overview {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
}
.overview > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.overview > .left {
    flex: 0.4;
}
.overview > .right {
    flex: 0.6;
}
.overview_img_box {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 40px;
    background-color: #fff;
}
.overview_img_box > li {
    width: calc(50% - 6px);
    aspect-ratio: 280/200;
    background-color: #d9d9d9;
}
.overview_img_box > li > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}
.overview .table_scroll_normal {
    width: 100%;
    overflow: auto;
}
.overview .mobile {
    display: none;
}
.wrap2 {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.wrap2 > li {
    width: 50% !important;
}
.list1 {
    border-top: 1px solid var(--Dark, #333);
    border-bottom: 1px solid var(--Dark, #333);
}

.list1 > li {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--Grey_D, #ddd);
}

.list1 > li > p {
    transform: skew(0deg);
}
.list1 > li > .title {
    color: var(--Grey_6, #666);
    /* Body */
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    background: var(--Tab, #ededed);
    width: 160px;
    line-height: 1.5;
    align-self: stretch;
}
.list1 > li > .data {
    color: var(--Dark, #333);
    /* Body */
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
    padding: 12px;
    line-height: 1.5;
    background-color: #fff;
    flex: 1;
    align-self: stretch;
    word-break: break-all;
}
.sub_header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 40px;
    background-color: #fff;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    position: fixed;
    top: calc(var(--header-height));
    left: 0;
    width: 100%;
    z-index: 1;
}
.sub_header > p {
    color: var(--Grey_6, #666);
    /* Title */
    font-size: 16px;
    font-weight: 400;
}
.sub_header > .bd {
    font-weight: 700;
}
.asset_box {
    display: flex;
    align-items: flex-start;
    position: relative;
}
.asset_file_box {
    width: 360px;
    background-color: #fff;
    align-self: stretch;

    position: fixed;
    top: calc(var(--header-height) + var(--sub-header-height));
    right: 0;

    max-height: calc(
        100dvh - (var(--header-height) + var(--sub-header-height))
    );
    height: 100%;
    display: flex;
    flex-direction: column;
}
.asset_file_box > .asset_file_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding: 40px;
}
.asset_file_box > .asset_file_content > div > .title {
    color: var(--Dark, #333);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    margin-bottom: 12px;
}
.asset_file_box > .asset_file_content > div > .add {
    cursor: pointer;
    background: url("../images/add_box.svg") no-repeat center right;
    background-size: 24px;
}
.file_list,
.comment_list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.file_list > li {
    border: 1px solid var(--Grey_C, #ccc);
    background: var(--White, #fff);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0 0 12px;
}
.file_list > li > p {
    line-height: 43px;
    width: 100%;
    flex: 1;

    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 300;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    display: -webkit-box;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
.file_list .pdf::after {
    content: "";
    width: 43px;
    height: 43px;
    background: url("../images/pdf.svg") no-repeat center right;
    background-size: 24px;
}

.comment_list > li {
    border: 1px solid var(--Grey_C, #ccc);
    background: var(--White, #fff);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}
.comment_list .date {
    color: var(--Dark, #333);
    font-size: 12px;
    font-weight: 400;
}
.comment_list .title {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    white-space: pre-line;
}
.comment_list .delete_btn_big {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
}

.pop_component {
    padding: 0 20px;
}
.pop_component .popup_btn {
    margin-top: 20px;
    gap: 10px;
}
.pop_component .popup_btn .btn {
    max-width: 120px;
}
.bell_box {
    position: fixed;
    top: calc(var(--header-height) - 10px);
    right: 10px;
    border-radius: 8px;
    border: 1px solid var(--Grey_C, #ccc);
    background: #fff;

    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    width: 360px;
    z-index: 999999;
}

.bell_box > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    border-bottom: 1px solid #eee;
    padding: 0 20px;
}
.bell_box > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 20px;
    font-weight: 700;
}
.bell_box > .title_box > .sub_title {
    color: var(--Grey_6, #666);
    font-size: 12px;
    font-weight: 400;
}
.alarm_list {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 60dvh;
    overflow: auto;
}
.alarm_list > li {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.alarm_list > li > .date {
    color: var(--Grey_9, #999);
    font-size: 12px;
    font-weight: 400;
}
.alarm_list > li > .title {
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.3;
}

.mapbox.drawing > div > div {
    cursor: crosshair !important;
}

.marker1 {
    width: 40px;
    height: 40px;
    background: url("../images/marker1.svg") no-repeat center center;
    background-size: 28px;
    transform: unset;
}
.marker2 {
    width: 40px;
    height: 40px;
    background: url("../images/marker2.svg") no-repeat center center;
    background-size: 28px;
    transform: unset;
}
.marker3 {
    width: 40px;
    height: 40px;
    background: url("../images/marker3.svg") no-repeat center center;
    background-size: 28px;
    transform: unset;
}
.marker4 {
    width: 40px;
    height: 40px;
    background: url("../images/marker4.svg") no-repeat center center;
    background-size: 28px;
    transform: unset;
}
.marker5 {
    width: 40px;
    height: 40px;
    background: url("../images/marker5.svg") no-repeat center center;
    background-size: 28px;
    transform: unset;
}
.marker6 {
    width: 40px;
    height: 40px;
    background: url("../images/marker6.svg") no-repeat center center;
    background-size: 28px;
    transform: unset;
}
.marker_dot {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #b80000;
}
.marker_radius {
    position: relative;
}
.marker_radius_box {
    display: flex;
    width: 140px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid var(--Grey_C, #ccc);
    background: var(--White, #fff);
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.08);
}
.marker_radius_box > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.marker_radius_box > .title_box > p {
    color: var(--Dark, #333);
    font-size: 12px;
}
.marker_radius_box > .title_box > p > span {
    color: var(--Primary, #0656cd);
    font-size: 12px;
    font-weight: 700;
}
.remove_circle {
    width: 116px;
    height: 36px;
    background: url("../images/remove_circle.svg") no-repeat center left 24px;
    background-size: 20px;
    color: var(--Dark, #333);
    font-size: 12px;
    font-weight: 700;
    padding-left: 16px;
    text-align: center;
    border-radius: 100px;
    border: 1px solid var(--Grey_C, #ccc);
    transform: unset;
}
.cadastral {
    background-color: var(--Primary, #0656cd);
}

.map_pop {
    position: fixed;
    top: 10%;
    right: 100px;
    width: 480px;

    border-radius: 8px;
    border: 1px solid var(--Grey_C, #ccc);
    background: #fff;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.map_pop > .title_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.map_pop_content {
    padding: 20px;
}
.map_pop_content > .title {
    color: var(--Dark, #333);
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;
    background: url("../images/right_arrow_black.svg") no-repeat center right;
    background-size: 20px;
    padding-right: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
    cursor: pointer;
}
.map_pop_content .list1 > li > .title {
    width: 112px;
}
.map_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999;
}

.loading_spin {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}
.cadastral_pop {
    position: fixed;
    top: 20%;
    left: calc(((100% + var(--lnb-width)) / 2) - 240px);
    width: 480px;

    border-radius: 8px;
    border: 1px solid var(--Grey_C, #ccc);
    background: #fff;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.cadastral_pop > .title_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.cadastral_pop_content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.cadastral_pop_content > li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 20px;
}
.cadastral_pop_content > li > .title {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 400;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: calc(100% - 28px);
    cursor: pointer;
}
.cadastral_pop_content > li > .title:hover {
    text-decoration: underline;
}
.cadastral_pop_btn_box {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 40px 50px;
}
.pop_form_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px 20px 20px;
}
.pop_form_one {
    display: flex;
    align-items: center;
}
.pop_form_one > p {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 400;
    width: 60px;
}
.pop_form_input_box {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}
.cadastral_add_btn {
    position: fixed;
    bottom: 56px;
    left: calc(((100% + var(--lnb-width)) / 2) - 195px);

    display: flex;
    width: 390px;
    height: 64px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Dark, #333);
    color: var(--White, #fff);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
.lnb_filter_btn {
    background: url("../images/up_gray.svg") no-repeat center right 10px;
    background-size: 0px;
    background-color: var(--Dark, #333);
    height: 56px;
    display: none;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--White, #fff);
    font-size: 16px;
    font-weight: 700;
    position: fixed;
    bottom: 0;
    left: -1%;
    width: 102%;
    z-index: 999999;
    transition: all 0.5s cubic-bezier(0.77, 0.31, 0.2, 0.99);
}
.lnb_filter_btn::before {
    content: "";
    background: url("../images/filter.svg") no-repeat center center;
    background-size: 24px;
    width: 24px;
    height: 24px;
    transition: all 0.5s cubic-bezier(0.77, 0.31, 0.2, 0.99);
}

.header_logo_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.input_search_button_toggle {
    background: url("../images/search.svg") no-repeat center center;
    background-size: 24px;
    width: 48px;
    height: 44px;
    display: none;
    position: relative;
}
.input_search_button_toggle.searchOpen {
    background: url("../images/exit.svg") no-repeat center center;
    background-size: 24px;
}
.input_search_button_toggle.active::after {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #f00;
    border-radius: 100%;
    position: absolute;
    top: 8px;
    right: 8px;
}
.filter_close_btn {
    background: url("../images/down_gray.svg") no-repeat center right 10px;
    background-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--Dark, #101010);
    font-size: 16px;
    font-weight: 700;
    background-color: #fff;
    height: 56px;
    width: 100%;
    border-bottom: 1px solid var(--Grey_D, #ddd);
}
.attached_btn {
    background: url("../images/up_gray.svg") no-repeat center right 10px;
    background-size: 0px;
    background-color: var(--Dark, #333);
    height: 56px;
    display: none;
    align-items: center;
    justify-content: center;
    color: var(--White, #fff);
    font-size: 16px;
    font-weight: 700;
    position: fixed;
    bottom: 0;
    left: -1%;
    width: 102%;
    z-index: 999999;
    transition: background-color 0.3s;
}
.attached_btnM {
    background: url("../images/down_gray.svg") no-repeat center right 10px;
    background-size: 20px;
    background-color: #fff;
    color: var(--Dark, #101010);
    border-bottom: 1px solid var(--Grey_D, #ddd);
    height: 56px;
    line-height: 56px;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
}
.search_box {
    background-color: #fff;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
}
.search_box > .btn_box {
    display: flex;
    align-items: center;
    gap: 10px;
}
.search_box > .btn_box > button {
    width: 120px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
}
.search_box_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
}

.search_box_wrap_center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.search_box_wrap_center .btn_wrap {
    width: 25%;
    display: flex;
    gap: 10px;
}

.search_box_wrap .search_form {
    width: 50%;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.search_box_wrap .search_box_wrapper {
    width: 50%;
    display: flex;
    margin-bottom: 20px;
}
.search_box_wrap .search_box_wrapper .input_section select {
    width: 200px;
}
.search_box_left {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}
.form_box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px;
    gap: 40px;
    border-radius: 20px;
}
.form_box.row {
    flex-direction: row;
    align-items: flex-start;
}
.form_box > .form_box_left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.form_box > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form_box > .title_box > .title {
    font-size: 20px;
    font-weight: 700;
}

.form_box > .btn_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.form_box > .btn_box > button {
    font-size: 13px;
    width: auto;
    height: 36px;
    padding: 0 25px;
    min-width: 120px;
}

.form_box > .title_box > .btn_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.form_box > .title_box > .btn_box > button {
    font-size: 13px;
    width: auto;
    height: 36px;
    padding: 0 25px;
    min-width: 120px;
}

.form_section_flex {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.form_section {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.form_section.m {
    width: 1000px;
}
.form_section.s {
    width: 500px;
}
.form_section > .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}
.form_section > .btn_box {
    display: flex;
    align-items: center;
    gap: 10px;
}
.form_section > .btn_box > button {
    font-size: 13px;
    width: auto;
    height: 42px;
    padding: 0 25px;
    min-width: 120px;
}

.form_list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.form_list_ul {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
}
.form_list_ul > li {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #ddd;
}
.form_list_ul > li:nth-last-of-type(1) {
    border-bottom: none;
}
.form_list_ul > li > .title {
    background-color: #ededed;
    color: #666;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    width: 160px;
}
.form_list_ul > li > div {
    padding: 0 12px;
}

.form_list .input_box {
}
.form_profile {
    display: flex;
    align-items: center;
    gap: 10px;
}
.form_profile > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
}
.form_profile > .title_box > .title {
    font-size: 18px;
    font-weight: 700;
}

.radio_box {
    display: flex;
    align-items: center;
    gap: 20px;
}

.master_auth_pop {
    padding: 0 20px;
}
.master_auth_pop > .title {
    font-size: 15px;
    margin-bottom: 8px;
    text-align: center;
}
.master_auth_pop > .popup_btn {
    margin-top: 20px;
}
.master_auth_pop .input_file_label_doc {
    justify-content: center;
}
.master_auth_pop .input_file_label_doc_names {
    max-width: calc(100% - 88px);
}
.search_form {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.search_form .input_box {
    display: flex;
    align-items: center;
    flex: unset;
}
.search_form .radio_box {
    gap: 0;
}

.search_form .input_box .input_label,
.search_form .radio_box .input_label {
    margin-bottom: 0;
    width: 100px;
}

.search_form .input_box select {
}
.search_form .input_box .input_section {
    width: 340px;
}
.search_form .input_section_area {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input_table > input,
.input_table > textarea {
    width: 100%;
    text-align: center;
    resize: none;
    padding: 8px;
    transform: none;
    outline: none;
    border: 1px solid #ddd;
}
.input_table > input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #333;
}
.input_table > input:focus {
    outline: none;
    border: 1px solid #333;
}

.input_table > input.red::placeholder {
    color: #ff0000;
}
.input_table_read > input {
    background-color: #eee;
}
.input_table_read > input::placeholder {
    color: #999;
}
.input_li > input {
    text-align: left !important;
    height: 100%;
    padding: 0;
}
.input_li > input:focus {
    border: none;
}
tfoot {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
}
.add_row td {
}
.add_row input {
    border-right: 1px solid #ddd;
}
.add_row_btn_box {
    border-right: 1px solid #ddd;
}
.add_row input:focus {
    border-left: 0;
}
.table_edit_scroll td {
    padding: 0;
}
.td_link {
    cursor: pointer;
    word-break: break-all;
}
.td_link:hover {
    text-decoration: underline;
}
.td_p {
    padding: 8px;
}
.td_img > img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
}

.input_box_thumb {
    width: 100%;
    height: 100%;
}
.input_box_thumb input {
    display: none;
}
.input_box_thumb_label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.input_box_thumb_label > label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.input_box_thumb_label > label > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}
.input_box_thumb_label > .btn_box {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}
.input_box_icon_label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.input_box_icon_label > label {
    background-color: #ccc;
    border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
}
.input_box_icon_label > label > p {
    color: #fff;
}
.input_box_icon_label > img {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.camera_icon {
    width: 30px !important;
}
.bottom_btn_box {
    margin-top: 20px;
}
.bottom_btn_box > button {
    height: 30px;
    line-height: 30px;
    width: 215px;
    font-size: 14px;
}
.title_box_link {
    display: flex;
    align-items: center;
    gap: 30px;
}
.title_box_link > p {
    color: var(--Dark, #999);
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
    cursor: pointer;
}
.title_box_link > .active {
    color: var(--Dark, #333);
    border-bottom: 4px solid #004889;
}
.table_normal {
    border-spacing: 0;
    width: 100%;
}

.log_pop {
    padding: 0 20px 40px 20px;
    max-height: 70dvh;
    overflow: auto;
}
.log_pop > .title {
    font-size: 15px;
    margin-bottom: 8px;
    text-align: center;
}
.input_color {
    display: flex;
    align-items: center;
    position: relative;
}

.input_color > input {
    width: 100%;
    text-align: center;
    resize: none;
    padding: 8px;
    transform: none;
    outline: none;
    border: 1px solid #ddd;
    flex: 1;
}
.input_color > .color_picker_bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.input_color > .color_picker_box {
    position: absolute;
    top: 0;
    right: -100%;
    z-index: 2;
}
.color_btn {
    border: 1px solid #333;
    width: 36px;
    height: 36px;
}

.input_img_list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.input_img_list > img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 8px;
}
.arrow_btn_box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.arrow_btn_box > button {
    background: url("../images/top.svg") no-repeat center center;
    background-size: 20px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid #e4e9f2;
    border-radius: 5px;
}
.arrow_btn_box > .down {
    transform: rotate(180deg);
    margin-left: 5px;
}
.search_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 1000px;
}
.search_list > li {
    display: flex;
    align-items: center;
}
.search_list > li > .title {
    width: 200px;
}
.search_list > li > .radio_box {
    flex-wrap: wrap;
    flex: 1;
}

.find_box {
    width: 400px;
    position: relative;
}
.find_list {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    position: absolute;
    background-color: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 10px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.find_list > p {
    cursor: pointer;
}
.find_list > p:hover {
    text-decoration: underline;
}
.choice_box {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex: 1;
}
.choice_box > li {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding-left: 12px;
}

.case_add_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    border: 1px dashed #d8dbe1;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.multi_table_detail > .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.multi_table_detail > .title_box > .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    color: #000;
    display: flex;
}
.multi_table_detail > .title_box > .title_btn_box {
    display: flex;
    align-items: center;
}

.multi_table_detail > .title_box > .title_btn_box > .btn,
.multi_table_detail > .btn {
    width: 120px;
    font-size: 14px;
    height: 38px;
    line-height: 38px;
    margin-left: 0;
}
.multi_table_detail > .title_box > .title_btn_box > .btn:nth-of-type(1) {
    margin-right: 10px;
}

.multi_table_detail > .btn {
    float: right;
    margin-top: 20px;
}

.casezone_box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #f7f9fc;
    border: solid 1px #e4e9f2;
    padding: 20px;
    margin-bottom: 14px;
    position: relative;
}
.casezone_box .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.casezone_box .titleBox > .title {
    font-size: 16px;
    font-weight: 700;
    color: #333;
}
.casezone_box .input_box {
    width: 240px;
    margin-top: 0;
    margin-bottom: 16px;
}
.casezone_box .input_box_full,
.casezone_box .textarea_box {
    width: 100%;
}
.casezone_box .input_box_select {
    margin-right: 12px;
}
.casezone_box > .trash_btn_red {
    position: absolute;
    top: 0;
    right: 0;
}

.one_line {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    gap: 20px;
}

.one_line.half_two > .input_box {
    flex: unset !important;
    width: 100%;
}
.input_box_radio .radio_box {
    flex-wrap: wrap;
}

.one_line .input_date_label {
    background-color: #fff;
}

.pop_progress {
    display: flex;
    position: relative;
    bottom: -1px;
    padding: 0 20px;
    margin: 20px 0;
    gap: 30px;
}
.pop_progress > p {
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #909090;
    padding-bottom: 9px;
    cursor: pointer;
}

.pop_progress > p.active {
    font-weight: bold;
    color: #2a9d8f;
    border-bottom: 2px solid #2a9d8f;
}

.select_user_box {
    height: 100%;
}
.select_user_box > .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select_user_box > .title_box > .title {
    color: var(--Dark, #333);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.select_user_box > .title_box > .btn_box > button {
    font-size: 13px;
    width: auto;
    height: 36px;
    padding: 0 25px;
    min-width: 120px;
}

.table_click > tr {
    cursor: pointer;
}
.table_click > tr.active > td {
    background-color: #ededed;
}

.check_box_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.table_box_overflow {
    max-height: calc(100dvh - 260px);
    overflow: auto;
}

/* 회원 상세 */
.membercontrolinfoArea {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
}
.membercontrolinfoArea > div {
    width: 100%;
}
.membercontrolheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.membercontrolheader > div {
    font-weight: 700;
}
.membercontrolheader > button {
    text-decoration: underline;
}
.membercontrolprofileImages {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.membercontrolprofileImages > img {
    width: 72px;
    height: 72px;
    border: 1px solid #eee;
    border-radius: 50%;
    object-fit: cover;
}
.membercontrolprofileImages > div {
    width: 72px;
    height: 72px;
    border: 1px solid #eee;
    border-radius: 50%;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
}

.membercontrolinputtextviewtype1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 8px;
}
.membercontrolinputtextviewtype1 > div:nth-child(1) {
    width: 90px;
    text-align: left;
    color: #666;
}
.membercontrolinputtextviewtype1 > div:nth-child(2) {
    width: calc(100% - 98px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1px;
}

.membercontroldivline {
    height: 1px;
    background-color: #eeeeee;
}
.membercontrolboldstitle {
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.membercontrolboldstitle > span {
    font-weight: 700;
}
.membercontrolTrophycountinfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    background: #fff;
}
.membercontrolTrophycountinfo > div {
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #666;
    font-size: 12px;
    gap: 8px;
}
.membercontrolTrophycountinfo > div > div:nth-child(1) {
    color: #666;
    font-size: 12px;
}
.membercontrolTrophycountinfo > div > div:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
}
.membercontrolTrophycountinfo > div > div.lightblue {
    color: #6164a5;
}
.membercontrolTrophycountinfo > div:nth-child(2) {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

/* 매물 상세 */
.ProductDetailArea {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1002;
}
.AlertPopArea {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1002;
}
.ProductDetailArea > div {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 100px;
}
.ProductDetailAreaBG {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1003;
    background-color: rgba(0, 0, 0, 0.5);
}
.AlertPopAreaBG {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1003;
    background-color: rgba(0, 0, 0, 0);
}
.ProductDetailpop {
    position: relative;
    background-color: #fff;
    z-index: 1004;
    width: 1200px;
}
.ProductSendDetailpop {
    position: relative;
    background-color: #fff;
    z-index: 1004;
    width: 600px;
    border-radius: 8px;
}
.ProductSendDetailpop.wd500 {
    width: 500px;
}
.ProductSendDetailpop2 {
    position: relative;
    background-color: #fff;
    z-index: 1004;
    width: 940px;
    border-radius: 8px;
}
.ProductSendDetailpop2 > div.doubleArea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.ProductSendDetailpop2 > div.doubleArea > div:nth-child(1) {
    width: calc(100% - 340px);
}
.ProductSendDetailpop2 > div.doubleArea > div:nth-child(2) {
    width: 340px;
    padding: 20px;
}
.ProductSendnolistpop {
    position: relative;
    background-color: #fff;
    z-index: 1004;
    width: 500px;
    border-radius: 8px;
}

.ProductpopTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ProductpopTitle > span {
    font-size: 24px;
    font-weight: 700;
}
.ProductDetailpop > div.ProductpopTitle {
    border-bottom: 1px solid #ddd;
}

.AlertPoplistArea {
    position: absolute;
    right: 10px;
    top: 66px;
    width: 400px;
    z-index: 1004;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #ccc;
    -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.slidenavigateButton {
    position: absolute;
    z-index: 1000;
    top: calc(50% - 14px);
    left: 0px;
    right: auto;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
.slidenavigateButton.right {
    right: 0px;
    left: auto;
}
.productDetailSlider {
    width: 100%;
    height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.setstyles {
    min-width: 72px;
    height: 32px;
    border: 1px solid #fff;
    border-left: 6px solid #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6px;
}
.setstyles.black {
    border: 1px solid #333;
    border-left: 6px solid #333;
}
.setstyles.green {
    border: 1px solid #33d330;
    border-left: 6px solid #33d330;
}
.setstyles.blue {
    border: 1px solid #5c96ed;
    border-left: 6px solid #5c96ed;
}
.setstyles.red {
    border: 1px solid #f00;
    border-left: 6px solid #f00;
}
.setstyles.orange {
    border: 1px solid #ff820f;
    border-left: 6px solid #ff820f;
}
.setstyles.gray {
    border: 1px solid #ccc;
    border-left: 6px solid #ccc;
}

.ProductpopContents {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    background-color: #f2f4f7;
}
.ProductpopContentsLeft {
    width: 100%;
}
.ProductpopContentsRight {
    width: calc(100% - 400px);
}
.ProductpopContentsRightarea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}
.ProductpopContentsRightarea > div {
    width: 100%;
}
.productinfoArea1,
.productinfoArea2 {
    padding: 20px;
}
.productnumberinfos {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.linedotTxt {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.linedotTxt > span.bar {
    display: inline-block;
    width: 1px;
    height: 12px;
    background-color: #ccc;
}
.productinfos1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}
.productinfos1 > .linedotTxt {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 40px;
}
.productinfos1.styles1 {
    font-size: 20px;
}
.productinfos1.styles3 {
    margin-top: 12px;
}
.productinfos1.styles4 {
    margin-top: 12px;
    padding: 12px 20px;
    border-radius: 4px;
    background-color: #f2f4f7;
    font-size: 16px;
    font-weight: 700;
}
.productinfos1.styles5 {
    padding: 12px 20px;
    border-radius: 4px;
    border: 1px solid #f2f4f7;
}

.productinfoArea2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}
.productinfoArea2 > div {
    width: 100%;
}
.productinfoArea2 > div > div.title_txt {
    font-size: 16px;
}
.productinfoArea2 > div > div.infos {
    margin-top: 8px;
    border-top: 1px solid #ddd;
}
.productinfoArea2 > div > div.infos > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}
.productinfoArea2 > div > div.infos > div > div {
    height: 33px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.productinfoArea2 > div > div.infos > div > div:nth-child(1) {
    width: 100px;
    background-color: #f1f1f1;
}
.productinfoArea2 > div > div.infos > div > div:nth-child(2) {
    width: calc(100% - 100px);
}
.productinfoArea2 > div > div.textareasinput {
    margin-top: 8px;
}
.productinfoArea2 > div > div.textareasinput > textarea {
    border-radius: 4px;
    padding: 12px;
    border: 1px solid #ddd;
    height: 100px;
    width: 100%;
}
.productinfoArea2 > div > div.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
}
.productinfoArea2 > div > div.buttons > button.productIssuebuttons {
    width: 49px;
    height: 33px;
    border-radius: 4px;
    border: 1px solid #333;
    font-weight: 700;
}

.ProductSendProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.ProductSendProfile > div:nth-child(1) {
    width: 64px;
    height: 64px;
    border: 1px solid #eee;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ProductSendProfile > div:nth-child(2) {
    width: calc(100% - 84px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
}
.ProductSendProfile > div:nth-child(2) > div:nth-child(2) {
    font-size: 20px;
    font-weight: 700;
}

.ProductSendInfos > div.infoTxts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    background-color: #f1f1f1;
}
.ProductSendInfos > div.infos {
    border-top: 1px solid #ddd;
}
.ProductSendInfos > div.infos > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}
.ProductSendInfos > div.infos > div > div {
    height: 33px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.ProductSendInfos > div.infos > div > div:nth-child(1) {
    width: 100px;
    background-color: #f1f1f1;
}
.ProductSendInfos > div.infos > div > div:nth-child(2) {
    width: calc(100% - 100px);
}
.sendmemobutton {
    background-color: #6164a5;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border-radius: 100px;
}

.Trophyarea {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.Trophyarea > span {
    font-size: 20px;
    font-weight: 700;
}
.Trophystickarea {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 12px;
}
.Trophystickarea > .trophysticker {
    border-radius: 4px;
    padding: 4px 12px;
    border: 1px solid #6164a5;
    font-size: 12px;
    font-weight: 700;
    background: #e1e2fc;
    color: #6164a5;
}
.Trophystickarea > .trophysticker.style1 {
    border: 1px solid #ff820f;
    background: #ff820f;
    color: #fff;
}
.Trophystickarea > .trophysticker.style2 {
    border: 1px solid #6164a5;
    background: #6164a5;
    color: #fff;
}
.Trophystickarea > .trophysticker.style3 {
    border: 1px solid #6164a5;
    background: #fff;
    color: #6164a5;
}
.Trophyphone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}
.Trophyaddr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    color: #666;
}
.Trophycountinfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #ddd;
    background: #fff;
    -webkit-box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.Trophycountinfo > div {
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #666;
    font-size: 12px;
    gap: 8px;
}
.Trophycountinfo > div > div:nth-child(1) {
    color: #666;
    font-size: 12px;
}
.Trophycountinfo > div > div:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
}
.Trophycountinfo > div > div.lightblue {
    color: #6164a5;
}
.Trophycountinfo > div:nth-child(2) {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.checkComment {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}
.checkComment > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.checkComment > div > div:nth-child(1) {
    width: 20px;
    font-size: 12px;
}
.checkComment > div > div:nth-child(2) {
    width: calc(100% - 20px);
    font-size: 12px;
}

.producttogetheralertArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.producttogetheralertArea > div:nth-child(1) {
    font-size: 20px;
    font-weight: 700;
}
.producttogetheralertArea > div:nth-child(3) {
    padding: 20px;
    border-radius: 4px;
    background: #f1f1f1;
}

.producttogetheralertArea2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.producttogetheralertArea2 > div:nth-child(1) {
    font-size: 14px;
}
.producttogetheralertArea2 > div:nth-child(2) {
    padding: 20px;
    border-radius: 4px;
    background: #f1f1f1;
}

.AlertPoplistArea > div.titleTxt {
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
}
.AlertPoplistArea > div.subtitleTxt {
    padding: 0px 20px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
}
.AlertPoplistArea > div.subtitleTxt > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 700;
}
.AlertPoplistArea > div.subtitleTxt > div > span {
    font-size: 16px;
    font-weight: 700;
}
.AlertPoplistArea > div.subtitleTxt > button {
    text-decoration: underline;
}
.AlertPoplistArea > div.bell_listArea {
    padding: 0px 20px;
}
.AlertPoplistArea > div.bell_listArea > div {
    padding: 12px 0px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    flex-direction: column;
}
.AlertPoplistArea > div.bell_listArea > div > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.AlertPoplistArea > div.bell_listArea > div > div:nth-child(1) > span {
    color: #666;
    font-size: 12px;
}
.AlertPoplistArea > div.bell_listArea > div > div.comments {
    width: 100%;
}
div.alertPoptypebuttons {
    margin-top: 4px;
}
div.alertPoptypebuttons > button {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 700;
    color: #6164a5;
}
.AlertPoplistArea > div.bellmoreButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0px;
}
.alerPoptypestickers {
    margin-bottom: 4px;
}
.alerPoptypestickers > span {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    background: #e1e2fc;
}

.matchinglist {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}
.matchinglist > div {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #ddd;
    width: 100%;
}

.productdamdangArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.productdamdangArea > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}
.productdamdangArea > div > div:nth-child(1) {
    width: 80px;
    color: #666;
}
.productdamdangArea > div > div:nth-child(2) {
    width: calc(100% - 80px);
}
.productdamdangArea > div:nth-child(3) {
    margin: 8px 0px;
}

.infoviewstyle1 {
    border: 1px solid #333;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
}
.infoviewstyle1 > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.infoviewstyle1 > div:nth-child(1) > button:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoviewstyle1 > div:nth-child(1) > button:nth-child(2) {
    font-size: 12px;
    color: #6164a5;
    text-decoration: underline;
}
.infoviewstyle1 > div:nth-child(2) {
    margin-top: 10px;
}
.infoviewstyle1 > div:nth-child(2) > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 4px 0px;
}
.infoviewstyle1 > div:nth-child(2) > div > div:nth-child(1) {
    width: 90px;
    font-size: 12px;
}
.infoviewstyle1 > div:nth-child(2) > div > div:nth-child(2) {
    width: calc(100% - 98px);
    font-size: 12px;
}

.infoviewstyle2 {
    border: 1px solid #333;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
}
.infoviewstyle2 > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.infoviewstyle2 > div:nth-child(1) > button:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
}
.infoviewstyle2 > div:nth-child(1) > button:nth-child(2) {
    font-size: 12px;
    color: #6164a5;
    text-decoration: underline;
}
.infoviewstyle2 > div:nth-child(2) {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}
.infoviewstyle2 > div:nth-child(3) {
    margin-top: 8px;
}
.infoviewstyle2 > div:nth-child(3) > div {
    border-bottom: 1px solid #ddd;
    padding: 4px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.infoviewstyle2 > div:nth-child(3) > div > div {
    width: 30%;
    text-align: center;
    font-size: 12px;
}
.infoviewstyle2 > div:nth-child(3) > div > div:nth-child(1),
.infoviewstyle2 > div:nth-child(3) > div > div:nth-child(2) {
    width: 20%;
}

div.meetinglist > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 8px;
    gap: 10px;
}
div.meetinglist > div > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
div.meetinglist > div > div:nth-child(1) {
    width: 90px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}
div.meetinglist > div > div:nth-child(2) {
    width: calc(100% - 100px);
}

.sendonoffArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.sendonoffArea > div {
    width: 160px;
}
.productsendlistArea {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
}
.productsendlistArea > div:nth-child(1) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
}
.productsendlistArea > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.QusPop1area > div:nth-child(1) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.QusPop1area > div:nth-child(1) > div.QusPop1areacontents {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.QusPop1area
    > div:nth-child(1)
    > div.QusPop1areacontents
    > div:nth-child(2)
    > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.QusPop1area
    > div:nth-child(1)
    > div.QusPop1areacontents
    > div:nth-child(2)
    > div
    > span {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #333;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 6px;
}
.QusPop1area
    > div:nth-child(1)
    > div.QusPop1areacontents
    > div:nth-child(2)
    > div
    > p {
    width: calc(100% - 23px);
}

.mettingArea {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.mettingArea > div.tips {
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    width: 100%;
}
.mettingArea > div.tips > div.tipcontents {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.mettingArea > div.tips > div.tipcontents > span {
    display: inline-block;
    width: 20px;
    padding-left: 5px;
}
.mettingArea > div.tips > div.tipcontents > p {
    width: calc(100% - 20px);
}
.mettingArea > div.meethistoryForm {
    width: 100%;
}
.mettingArea > div.meethistoryForm > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.mettingArea > div.meethistoryForm > div:nth-child(2) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    margin-top: 9px;
}
.mettingArea > div.meethistoryForm > div:nth-child(2) > div:nth-child(1) {
    width: calc(100% - 88px);
}
.mettingArea
    > div.meethistoryForm
    > div:nth-child(2)
    > div:nth-child(1)
    > textarea {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ddd;
    height: 40px;
    padding: 4px 12px;
}
.mettingArea > div.meethistoryForm > div:nth-child(2) > div:nth-child(2) {
    width: 80px;
}
.meettingdatalist {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}
.meettingdatalist > div {
    border-radius: 8px;
    border: 1px solid #6164a5;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}
.meettingdatalist > div > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.favoritArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
}
.favoritArea > div {
    width: calc(50% - 10px);
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 20px;
}
.bolds {
    font-weight: 700;
}
.color666 {
    color: #666;
}
.colorCCC {
    color: #ccc;
}
.color999 {
    color: #999;
}
.colorF00 {
    color: #f00;
}
.color6164A5 {
    color: #6164a5;
}
/* 매물 상세 끝 */
.ChkBoxWrap {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.ChkBoxWrap .title {
    width: 100px;
}
.ChkBox {
    display: flex;
    gap: 10px;
}
.ChkBoxOne {
    margin-right: 10px;
}
.ChkBoxOne input {
    display: none;
}
.ChkBoxOne input + label {
    display: inline-flex;
    align-items: center;
    padding-left: 28px;
    line-height: 20px;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
    background: url("../images/checkbox_off.svg") no-repeat center left;
    background-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
    word-break: keep-all;
}
.ChkBoxOne input:checked + label {
    background: url("../images/checkbox_on.svg") no-repeat center left;
    background-size: 20px;
}

.OneChkBox {
    display: none;
}

.OneChkBox + label {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../images/checkbox_off.svg") no-repeat center;
    background-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}
.OneChkBox:checked + label {
    background: url("../images/checkbox_on.svg") no-repeat center;
    background-size: 20px;
}

.TableState {
    width: 75px;
    height: 25px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.TableBtns {
    gap: 5px;
}
.TableBtns button {
    width: 50px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2a9d8f;
    color: #2a9d8f;
    background-color: #ebffef;
}
.TableBtns button:last-child {
    color: red;
    background-color: white;
    border: 1px solid #ccc;
}

.BoxInfo {
    width: 100%;
    height: 45px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.BoxInfo .title {
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-self: start;
    padding-left: 10px;
    background-color: #f1faee;
}
.BoxInfo .sub {
    padding-left: 10px;
    display: flex;
    height: 100%;
    width: 85%;
    align-items: center;
    justify-self: start;
    background-color: white;
}
.BoxInfo .sub input {
    margin-right: 10px;
    height: 80%;
    border: 1px solid var(--Grey_D, #ddd);
    padding: 0px 12px;
    background-color: var(--Grey_D, #fff);
    border-radius: 4px;
    font-size: 14px;
}

.BoxInfo .datePickRadio .react-datepicker-wrapper {
    width: auto;
}
.BoxInfo .sub input[readonly] {
    background-color: #dcdcdc;
    border: none;
}
.BoxInfo .sub textarea[readonly] {
    background-color: #dcdcdc;
    border: none;
}
.BoxInfo .sub select {
    padding-left: 10px;
    margin-right: 10px;
    height: 80%;
    border: 1px solid var(--Grey_D, #ddd);
    padding: 0px 12px;
    background-color: var(--Grey_D, #fff);
    border-radius: 4px;
    font-size: 14px;
}
.BoxInfo .RadioBoxOne {
    display: flex;
    margin-right: 10px;
}
.BoxInfo .sub .react-datepicker__input-container {
    display: flex;
    align-items: center;
}
.BoxInfo .sub2 {
    height: 100%;
    width: 85%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding-left: 10px;
}
.BoxInfo .sub2 select {
    height: 35px;
    border: 1px solid var(--Grey_D, #ddd);
    padding: 0px 12px;
    background-color: var(--Grey_D, #fff);
    border-radius: 4px;
    font-size: 14px;
}
.BoxInfo .sub .depositBox {
    color: white;
    background-color: #ccc;
    width: 100px;
    height: 30px;
}
/* .react-datepicker__input-container {
    display: flex;
    align-items: center;
} */
.BoxInfo .ASRadioSub {
    padding-left: 10px;
}
.BoxInfo .ASRadioSub .RadioWrap {
    display: flex;
    margin-bottom: 10px;
}
.ConsultClk {
    display: flex;
    margin-top: 15px;
}
.BorderBox {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 20px 0px 10px 0px;
    background-color: #fff;
}
.BorderBox .title {
    display: flex;
    align-items: center;
}
.BorderBox .title h1 {
    font-size: 18px;
    margin: 10px 0px;
}
.BorderBox .HistoryBox {
    justify-content: space-between;
}

.BorderBox .HistoryBox .left {
    display: flex;
    align-items: center;
}
.BorderBox .HistoryBox .left h2 {
    margin-right: 20px;
    font-size: 18px;
}
.BorderBox .HistoryBox .left span {
    font-size: 16px;
}
.FlexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.HistoryBoxcontent {
    margin: 20px 0px;
}
.BorderBoxTable table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.BorderBoxTable td {
    border-bottom: 1px solid var(--Grey_D, #ddd);
    padding: 12px 8px;
    text-align: center;
    background-color: #fff;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
    word-break: keep-all;
}

.BorderBoxTable th {
    color: var(--Grey_6, #666);
    text-align: center;
    font-size: 14px;
    background: var(--Tab, #f1faee);
    height: 44px;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    border-top: 1px solid var(--Dark, #ddd);
    padding: 4px;
    word-break: keep-all;
}

.BorderBox .contentWrap {
    margin: 10px 0px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
.BorderBox .contentWrap {
    margin-top: 10px;
}
.BorderBox .BtnWrap {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.BorderBox .contentSplitWrap {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.BorderBox .contentSplitWrap .left {
    width: 49%;
}
.BorderBox .contentSplitWrap .right {
    width: 49%;
}
.input_box_select_custom {
    display: flex;
    align-items: center;
}
.input_box_select_custom .input_label {
    width: 100px;
}
.input_box_select_custom .input_section {
    width: 80%;
}
.title_container {
    display: flex;
    justify-content: space-between;
}
.title_container .title {
    color: var(--Dark, #333);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.title_container .title_btn {
    width: 150px;
    height: 40px;
    background-color: blue;
    color: white;
    font-size: 16px;
}

.title_color_box {
    border: 1px solid #ccc;
    margin-top: 20px;
    background-color: white;
}
.title_color_box .box_title {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;
}
.title_color_box .box_title h1 {
    font-size: 20px;
}
.title_color_box .contentColorWrap {
    padding: 10px 30px;
}
.title_color_box .contentColorWrap h3 {
    font-size: 18px;
}
.title_color_box .contentColorWrap .contentBorderWrap {
    border-top: 1px solid #ccc;
}

.MemoBoxWrap {
    border-top: 4px solid #ccc;
}
.MemoBoxWrap .title {
    display: flex;
    align-items: center;
    padding: 10px 0px;
}
.MemoBoxWrap .title img {
    width: 30px;
}
.MemoBoxWrap .title p {
    font-size: 23px;
    font-weight: 700;
    margin-left: 10px;
}
.MemoBoxWrap .MemoWrap {
    background-color: #f8f7fc;
    padding: 0px 30px 15px 30px;
}
.MemoBoxWrap .MemoWrap .radioWrap {
    background-color: #f8f7fc;
    padding: 10px 0px;
    display: flex;
    gap: 10px;
}
.MemoBoxWrap .MemoWrap textarea {
    /* 텍스트 에리아 크기 고정 */
    resize: none;
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
}
.MemoBoxWrap .MemoWrap .btnWrap {
    margin-top: 10px;
    text-align: right;
    width: 100%;
}
.MemoBoxWrap .MemoWrap .btnWrap button {
    width: 150px;
    height: 40px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.ResvDate {
    gap: 10px;
}
.BoxInfo .ResvDate .react-datepicker-wrapper {
    width: auto;
}
.ResvDate .ResvBtn {
    width: 100px;
    padding: 5px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.SaveBtn {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 20px 0px;
}
.BtnEnd {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 20px 0px;
}
.table_memo {
}
.table_memo table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}
.table_memo table th {
    color: var(--Grey_6, #666);
    text-align: center;
    font-size: 14px;
    background: var(--Tab, #ededed);
    height: 44px;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    border-top: 1px solid var(--Dark, #333);
    padding: 4px;
    word-break: keep-all;
}
.table_memo table td {
    border-bottom: 1px solid var(--Grey_D, #ddd);
    padding: 12px 8px;
    text-align: center;
    background-color: #fff;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
    word-break: keep-all;
}
.twoBtn_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0px;
}
.BoxProgressInfo {
    background-color: #f1faee;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 30px 0px;
    height: 50px;
}
.BoxProgressInfo .title {
    font-weight: 700;
    width: 10%;
    font-size: 18px;
}
.BoxProgressInfo .progress {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}
.BoxProgressInfo .progress button {
    margin-left: 20px;
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    background-color: #2a9d8f;
    color: white;
    font-weight: 700;
    justify-content: center;
}
.ChangeBtn {
    width: 100px;
    height: 30px;
    background-color: #ff6c6c;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.TableBox {
    margin: 20px 0px;
}
.TableBox table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}
.TableBox table th {
    color: var(--Grey_6, #666);
    text-align: center;
    font-size: 14px;
    background: var(--Tab, #f1faee);
    height: 44px;
    border-bottom: 1px solid var(--Grey_D, #ddd);
    border-top: 1px solid var(--Dark, #333);
    padding: 4px;
    word-break: keep-all;
}
.TableBox table th:nth-child(2) {
    background-color: white;
}
.TableBox table th:nth-child(3) {
    background-color: #41b06e;
    color: white;
}
.TableBox table th:nth-child(4) {
    background-color: #fb8500;
    color: white;
}
.TableBox table th:nth-child(5) {
    background-color: #caf0f8;
}
.TableBox table td {
    padding: 12px 8px;
    text-align: center;
    background-color: #fff;
    color: var(--Dark, #333);
    border-bottom: 1px solid var(--Grey_D, #ddd);
    font-size: 14px;
    font-weight: 400;
    word-break: keep-all;
}
.TableBox table td:first-child {
    background-color: #f1faee;
    font-weight: 700;
}
.TableBox table td:nth-child(3) {
    background-color: #41b06e;
    color: white;
}
.TableBox table td:nth-child(4) {
    background-color: #fb8500;
    color: white;
}
.TableBox table td:nth-child(5) {
    background-color: #caf0f8;
}
.CalenderBox {
    display: flex;
    gap: 10px;
    width: 100%;
    background-color: #f1faee;
    margin-top: 20px;
}

.CalenderBox .CalenderBoxWrap {
    width: 20%;
    padding: 10px;
    display: flex;
    align-items: center;
}
.WeekDate {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    gap: 10px;
}
.WeekDate p {
    font-size: 18px;
    font-weight: 700;
}
.WeekDate button {
    font-size: 14px;
    font-weight: 500;
    background-color: #cbf3f0;
    color: #2a9d8f;
    width: 50px;
    height: 30px;
    padding: 0 10px;
    transition: all 0.3s;
    min-width: 100px;
}
.materialmanage {
    display: flex;
    height: 50px;
    width: 70%;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.materialmanage p {
    font-size: 16px;
}
.materialmanage input {
    height: 80%;
    border: 1px solid var(--Grey_D, #ddd);
    padding: 0px 12px;
    background-color: var(--Grey_D, #fff);
    border-radius: 4px;
    font-size: 14px;
}
.inventManage .return .returnWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}
.inventManage .return {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.inventManage .return .returnWrap .input_box {
    width: 0px;
    height: 44px;
}
.inventManage .return .returnWrap .react-datepicker-wrapper {
    width: auto;
    height: 44px;
}
.inventManage .return .returnWrap select {
    display: inline-block;
    height: 44px;
    padding: 0 12px;
    border: 1px solid var(--Grey_D, #ddd);
    background: var(--White, #fff);
    border-radius: 4px;
    font-size: 14px;
    color: #000;
    width: 180px;
}

.logSearchResult {
    display: flex;
    align-items: center;
    background-color: #f1faee;
    height: 50px;
    padding: 10px;
    gap: 30px;
    margin: 20px 0px;
    font-size: 16px;
}
.SelectedWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 44px;
}
.SelectedWrap .SelectedWrapTitle {
    width: 100px;
    font-size: 16px;
}
.SelectedWrap .SelectedWrapContent {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 100%;
}
.SelectedWrap .SelectedWrapContent select {
    width: 20%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../images/down_black.svg") no-repeat center right 12px;
    background-size: 24px;
    background-color: #fff;
    padding: 0 44px 0 12px;
    position: relative;
    border: 1px solid var(--Grey_D, #ddd);
    height: 100%;
    border-radius: 4px;
}

.ClassifyWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
.ClassifyWrap .ClassifyContentWrap {
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.ClassifyWrap .ClassifyContentWrap .ClassifyContent {
    width: 150px;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
    flex: 1;
    text-align: center;
    background-color: #cbf3f0;
    color: #2a9d8f;
    border-right: 1px solid #ddd;
}

.ClassifyWrap .ClassifyContentWrap .ClassifyContent:last-child {
    border-right: none;
}
.ClassifyWrap .ClassifyContentWrap .ClassifyContent.active {
    background-color: #2a9d8f;
    color: white;
}
.BorderBox .leftContent {
    width: 49%;
}
.BorderBox .leftContent .contentWrap .BoxInfo .title {
    width: 20%;
}
.BorderBox .rightContent {
    width: 49%;
}
.OutcomingSearch {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    align-items: center;
}

.OutcomingSearch .RadioWrap {
    display: flex;
    gap: 20px;
}
.OutcomingSearch .SearchBoxWrap {
    display: flex;
    gap: 20px;
    width: 30%;
}
.TemplateWrap {
    display: flex;
    justify-content: space-between;
}

.SystemMessage {
    height: 100%;
    width: 85%;
    background-color: white;
    display: grid;
    column-gap: 10px;
    padding: 10px;
}
.SystemMessage .buttonWrap {
    display: flex;
    gap: 10px;
}
.SystemMessage .buttonWrap button {
    display: flex;
    gap: 10px;
    width: 150px;
}

.bannerWrap .bannerUrl {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.bannerWrap .bannerUrl .title {
    font-size: 18px;
    width: 150px;
}
.bannerWrap .bannerUrl .BannerInput {
    display: flex;
    width: 60%;
}
.bannerWrap .bannerUrl .BannerBtn {
    display: flex;
    width: 10%;
    gap: 10px;
}
.bannerWrap .bannerImg {
    background-color: #f1faee;
    display: flex;
    width: 74%;
    margin-left: 160px;
    margin-top: 20px;
}

.SelectBoxWrap {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 44px;
}
.SelectBoxWrap select {
    width: 20%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../images/down_black.svg") no-repeat center right 12px;
    background-size: 24px;
    background-color: #fff;
    padding: 0 44px 0 12px;
    position: relative;
    border: 1px solid var(--Grey_D, #ddd);
    height: 100%;
    border-radius: 4px;
}
.ChangeIndexWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.BottomContent {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
}
.BottomContent .ButtonContent {
    display: flex;
    gap: 10px;
}
.BottomContent .ButtonContent button {
    display: flex;
    padding: 10px;
    color: white;
    align-items: center;
    justify-content: center;
    width: 120px;
}

.excelContent {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    color: white;
    font-size: 14px;
    background-color: black;
}
.tabComponentWrap {
    width: 100%;
    display: flex;
    justify-content: center;
}
.tabComponentWrap .tabComponentWrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
}
.tabComponentWrap .tabComponentWrapper .tabComponent {
    width: 150px;
    padding: 10px;
    background-color: #cbf3f0;
    color: #2a9d8f;
    font-weight: bold;
    font-size: 18px;
    border-right: 1px solid white;
    flex: 1;
    text-align: center;
    cursor: pointer;
}

.tabComponentWrap .tabComponentWrapper .tabComponent.active {
    background-color: #2a9d8f;
    color: white;
}
.tabComponentWrap .tabComponentWrapper .tabComponent.disabled {
    background-color: #bfbfbf;
    color: white;
}
.subTitleCircle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}
.subTitleCircle .GreenCircle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #51baa4;
}
td .flexWrap {
    display: flex;
    align-items: center;

    gap: 10px;
}
.errorPStyle {
    color: red;
    font-size: 16px;
    font-weight: 700;
}
.ImgInputWrap.border-bot {
    border: none;
    border-bottom: 1px solid #ddd;
}

.ImgInputWrap {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    background-color: #f1faee;
}

.ImgInputWrap .Imgtitle {
    width: 15%;
    padding: 10px;
}
.ImgInputWrap .sub {
    background-color: white;
    padding: 10px;
    width: 85%;
    position: relative;
    min-height: 40px;
}
.grid_container .ContainerWrap {
    background-color: #ddd;
    padding: 5px;
}
.grid_container .ContainerWrap .count {
    font-size: 18px;
    display: flex;
    font-weight: 700;
    padding: 10px;
}
.grid_container .ContainerWrap .buttonWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.grid_container .ContainerWrap .buttonWrap .tableInputWrap {
    width: 100%;
}
.grid_container .ContainerWrap .buttonWrap .btn_box {
    width: 15%;
}
.grid_container .ContainerWrap .buttonWrap .btn_box button {
    height: 44px;
    font-size: 16px;
    padding: 10px;
}
